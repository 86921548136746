<template>
    <transition name="slide-fade" mode="out-in">
        <div v-if="noteLayout" class="abc">
            <div class="flex">
                <div class="w-10 h-10 rounded-full relative flex-shrink-0 mr-3">
                    <img :src="data.author.images !== '' && data.author.images !== null ? data.author.images : require('@/assets/images/avatars/avatar-1.jpg')" alt="" class="absolute h-full rounded-full w-full">
                </div>
                <div class="w-full">
                    <div class="flex justify-between w-full">
                        <div>
                            <h5 class="font-bold mb-0 color-dark">{{ data.author.fullname}}</h5>
                            <span class="color-muted"> {{ formatDate(data.created_at) }} </span>
                        </div>
                        <div v-if="showReply">
                            <a v-if="!replyForm.show" href="#" @click.prevent="replyForm.show = !replyForm.show" class="btn btn-outline-primary btn-note">Reply</a>
                            <a v-else href="#" @click.prevent="replyForm.show = !replyForm.show" class="btn btn-outline-primary btn-note">Close</a>
                        </div>
                    </div>
                    <div class="mt-2 w-full">
                        <p v-linkified>{{ data.content }}</p>

                        <div v-if="data.attachments.length > 0" class="mb-2 pl-2">
                            <div v-for="(item, index) in data.attachments" :key="index" >
                                <img v-if="['png', 'jpg', 'jpeg', 'gif'].includes(item.file_type)" :src="item.file_url" class="img-thumbnail w-25">
                                <div v-else class="flex">
                                    <div class="flex items-center justify-between comment-attachment">
                                        <div class="flex items-center">
                                            <Icon icon="mdi:file" class="fs-30 p-1.5 rounded-full comment-attachment-icon" />
                                            <span>{{ item.file_name }}</span>
                                        </div>
                                        <a :href="item.file_url" :download="item.file_name" class="ml-5">
                                            <Icon icon="carbon:download" class="fs-18" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="computedChildren.length > 0" class="comment-child ml-0">
                            <comment-item v-for="item in computedChildren" :key="item.id" :data="item" :add-comment="addComment" :show-reply="false" :note-layout="true" />
                        </div>

                        <transition name="slide-fade" mode="out-in">
                            <div v-if="replyForm.show" class="mb-2 mt-2 w-full">
                                <comment-form :parent-id="data.id" :add-comment="addComment" @append="onAppend" :show="replyForm.show" :note-layout="true" />
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
        <div v-else :class="'commentitem-' + data.id">
            <div class="flex comment-item uk-animation-slide-bottom-small" :class="user.id === data.author.id ? 'author-comment-left' : ''">
                <span class="line-has-child" v-if="computedChildren.length > 0"></span>
                <div class="w-10 h-10 rounded-full relative flex-shrink-0">
                    <img :src="data.author.images !== '' && data.author.images !== null ? data.author.images : require('@/assets/images/avatars/avatar-1.jpg')" alt="" class="absolute h-full rounded-full w-full">
                </div>
                <div class="comment-content transition-height">
                    <div class="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative dark:bg-gray-800 dark:text-gray-100">
                        <p class="leading-6" v-linkified>
                            <strong>{{ data.author.fullname}}</strong>
                            <br>{{ data.content }}
                        </p>
                        <div v-if="data.attachments && data.attachments.length > 0">
                            <img v-for="item in data.attachments" :key="item.id" :src="item.file_url" class="rounded-2" style="cursor:pointer;" @click="popupImage(item.file_url)">
                        </div>
                        <div class="comment-bubble bg-gray-100 dark:bg-gray-800"></div>
                        <span class="comment-delete" @click.prevent="onDelete(data.id)" v-if="data.author.id == user.id"><Icon icon="ep:delete" /></span>
                    </div>
                    <div class="text-sm flex items-center space-x-3 mt-2 ml-5 like-container">
                        <a href="#" class="text-red-600 hover:text-red-600 focus:text-red-600 like-container" @click.prevent="unlikeComment(data.id)" v-if="computedIsLiked" >
                            <span>{{ data.likes.length ? data.likes.length : 0 }}</span> <heart-icon-filled />
                        </a>
                        <a href="#" class="text-red-600 hover:text-red-600 focus:text-red-600 like-container" @click.prevent="likeComment(data.id)" v-else>
                            {{ data.likes.length ? data.likes.length : 0 }} <heart-icon />
                        </a>
                        <a v-if="showReply" href="#" @click.prevent="replyForm.show = !replyForm.show">{{ replyForm.show ? 'Cancel' : 'Reply' }}</a>
                        <span> {{ formatDateFromNow(data.created_at) }} </span>
                    </div>
                    <transition name="slide-fade" mode="out-in">
                        <div v-if="replyForm.show" class="mb-2 mt-2">
                            <comment-form :parent-id="data.id" :add-comment="addComment" @append="onAppend" :show="replyForm.show" />
                        </div>
                    </transition>
                </div>
            </div>
            <div v-if="computedChildren.length > 0" class="comment-child">
                <comment-item v-for="item in computedChildren" :key="item.id" :data="item" :add-comment="addComment" :show-reply="false" />
            </div>
        </div>
    </transition>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import HeartIconFilled from '@/components/Icon/HeartIconFilled'
    import HeartIcon from '@/components/Icon/HeartIcon'
    import CommentForm from './CommentForm.vue'
    import { Icon } from '@iconify/vue2'

    export default {
        name: 'comment-item',
        components: { HeartIcon, HeartIconFilled, CommentForm, Icon },
        props: {
            showReply: {
                type: Boolean,
                default: true
            },
            data: {
                type: Object,
                default: () => ({
                    author: {},
                    attachments: []
                })
            },
            addComment: {
                type: Function,
                required: true
            },
            noteLayout: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                replyForm: {
                    show: false
                },
                local: {
                    changed: false,
                    is_liked: false,
                    children: []
                }
            }
        },
        computed: {
            ...mapState('auth', ['user']),
            computedIsLiked () {
                if (this.local.changed) return this.local.is_liked
                return this.data.is_liked
            },
            computedChildren () {
                return [...this.data.children, ...this.local.children]
            }
        },
        methods: {
            ...mapActions('comments', ['like', 'unlike', 'delete']),
            likeComment (id) {
                this.like(id)
                    .then(() => {
                        this.local.changed = true
                        this.local.is_liked = true
                        this.data.likes.length += 1
                    })
            },
            unlikeComment (id) {
                this.unlike(id)
                    .then(() => {
                        this.local.changed = true
                        this.local.is_liked = false
                        this.data.likes.length -= 1
                    })
            },
            popupImage (url) {
                this.$swal({
                    imageUrl: url,
                    showCloseButton: true,
                    showConfirmButton: false
                })
            },
            onDelete (id) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.delete(id).then(() => {
                            document.querySelector('.commentitem-' + id).remove()
                            this.$swal(
                                'Deleted!',
                                'Your comment has been deleted.',
                                'success'
                            )
                        })
                    }
                })
            },
            onAppend (data) {
                this.replyForm.show = false
                this.local.children.push(data)
                this.$forceUpdate()
            }
        }
    }
</script>
