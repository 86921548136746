<template>
    <div>
        <div v-if="attachments.length > 0" class="mb-2 pl-2">
            <div v-for="(item, index) in attachments" :key="index" >
                <img v-if="item.type.includes('image')" :src="getFileUrl(item)" class="img-thumbnail w-25">
                <div v-else class="flex">
                    <div class="flex items-center justify-between comment-attachment">
                        <div class="flex items-center">
                            <Icon icon="mdi:file" class="fs-30 p-1.5 rounded-full comment-attachment-icon" />
                            <span>{{ item.name }}</span>
                        </div>
                        <a href="" @click.prevent="removeAttachment(index)" class="ml-5">
                            <Icon icon="mdi:delete" class="fs-18" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-gray-100 relative dark:bg-gray-800 border-t" :class="{ 'rounded-full': !noteLayout, 'rounded-md': noteLayout }">
            <input :id="inputId" ref="input-comment" :disabled="loading" placeholder="Add your Comment.." class="bg-transparent max-h-10 shadow-none comment-input" :class="{ loading: loading, 'radius-5': noteLayout }" v-model="content" @keyup.enter="onAddComment">
            <div class="absolute bottom-0 flex items-center right-3 text-xl" style="margin: 5px;">
                <!-- <a href="#"> -->
                    <!-- <ion-icon name="happy-outline" class="hover:bg-gray-200 p-1.5 rounded-full"></ion-icon> -->
                    <!-- <Icon icon="akar-icons:face-happy" class="hover:bg-gray-200 p-1.5 fs-30 rounded-full" />
                </a> -->
                <a href="javascript:void(0)">
                    <div class="position-relative input-file">
                        <input type="file" @change="onFileChange" accept="image/*">
                        <Icon icon="bx:bx-image" class="hover:bg-gray-200 fs-30 p-1.5 rounded-full" />
                    </div>
                </a>
                <a href="javascript:void(0)">
                    <div class="position-relative input-file">
                        <!-- Input only accept documents -->
                        <input type="file" @change="onFileChange" accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf">
                        <Icon icon="mdi:attach-file" class="hover:bg-gray-200 fs-30 p-1.5 rounded-full" />
                    </div>
                </a>
                <a href="javascript:void(0)" @click.prevent="onAddComment">
                    <div class="position-relative input-file">
                        <Icon icon="carbon:send-alt-filled" class="hover:bg-gray-200 fs-30 p-1.5 rounded-full" />
                    </div>
                </a>
                <!-- <a href="#"> -->
                    <!-- <ion-icon name="link-outline" class="hover:bg-gray-200 p-1.5 rounded-full"></ion-icon> -->
                    <!-- <Icon icon="entypo:link" class="hover:bg-gray-200 fs-30 p-1.5 rounded-full" />
                </a> -->
                <b-spinner v-if="loading" label="Loading..." small />
            </div>
        </div>
    </div>
</template>

<script>
    import { Icon } from '@iconify/vue2'

    export default {
        props: {
            addComment: {
                type: Function,
                required: true
            },
            parentId: {
                type: [Number, String],
                required: false
            },
            show: {
                type: Boolean,
                default: false
            },
            inputId: {
                type: String,
                default: ''
            },
            noteLayout: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            show (old) {
                console.log(old, 'show')
            }
        },
        components: { Icon },
        data () {
            return {
                loading: false,
                content: '',
                attachments: []
            }
        },
        methods: {
            onAddComment () {
                const { content, parentId, attachments } = this
                if (content !== '') {
                    this.loading = true
                    this.addComment({ content, parentId, attachments })
                        .then((data) => {
                            this.$emit('append', data)
                            this.content = ''
                            this.attachments = []
                        })
                        .catch(() => {
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something Wrong'
                            })
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            },
            onFileChange (event) {
                this.attachments = [...event.target.files]
            },
            removeAttachment (index) {
                this.attachments.splice(index, 1)
            },
            getFileUrl (file) {
                return URL.createObjectURL(file)
            }
        }
    }
</script>
